import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import ImageFrame from "../../../components/image-frame"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"

const HoelderlinLiebtMenschenPage = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        bueste: file(
          relativePath: { eq: "leichte-sprache/bueste-ausstellung.jpg" }
        ) {
          ...mediumImage
        }
        rike: file(relativePath: { eq: "hoelderlin-liebt/portrait-rike.jpg" }) {
          ...mediumImage
        }
        karl: file(relativePath: { eq: "hoelderlin-liebt/portrait-karl.jpg" }) {
          ...mediumImage
        }
        mutter: file(relativePath: { eq: "leichte-sprache/mutter.jpg" }) {
          ...mediumImage
        }
        vater: file(relativePath: { eq: "leichte-sprache/vater.jpg" }) {
          ...mediumImage
        }
        schelling: file(relativePath: { eq: "leichte-sprache/schelling.jpg" }) {
          ...mediumImage
        }
        sinclair: file(relativePath: { eq: "leichte-sprache/sinclair.jpg" }) {
          ...mediumImage
        }
        titelbild: file(
          relativePath: { eq: "hoelderlin-liebt/einleitung-menschen.jpg" }
        ) {
          ...mediumImage
        }
        hegel: file(relativePath: { eq: "leichte-sprache/hegel.jpg" }) {
          ...mediumImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt die Menschen"
        description="Die letzten 36 Jahre seines Lebens verbringt Hölderlin bei der Familie Zimmer in Tübingen."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "… die Menschen",
                link: "/sonderausstellungen/hoelderlin-liebt/menschen",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt die Menschen</PageTitle>
          <Grid columns={[1, 2]} alignY="center">
            <Paragraph dropcap={true}>
              Hölderlin ist gerne unter Menschen. <br />
              Er redet gerne. <br />
              Er feiert gerne. <br />
              Von unterwegs schreibt er lange Briefe
              <br />
              an seine Familie und seine Freunde.
              <br />
              <br />
              Das sind die wichtigsten Menschen in Hölderlins Leben.
            </Paragraph>
            <ImageFrame>
              <Image
                image={data.titelbild.childImageSharp.gatsbyImageData}
                attribution="Jette von Bodecker"
                alt="Eine Illustration zeigt die ganze Familie Hölderlin"
              />
            </ImageFrame>
          </Grid>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Die Mutter Johanna Christiana und der Vater Heinrich Friedrich
          </Heading>
          <Grid columns={[1, 2]} space={[6, 3]}>
            <Image
              image={data.mutter.childImageSharp.gatsbyImageData}
              alt="Hölderlins Mutter hat ein schmales Gesicht. Ihre Haare sind nach oben gesteckt. Sie trägt ein kostbares Kleid. Es hat ein Muster aus kleinen braunen und orangenen Blumen. Das Kleid ist oben ausgeschnitten und hat weiße Stickränder."
              caption="Hölderlins Mutter"
            />
            <Image
              image={data.vater.childImageSharp.gatsbyImageData}
              alt="Hölderlins Vater hat ein rundes Gesicht. Er trägt eine graue Perücke mit hochgewickelten Locken. Er ist gekleidet mit einem blauen Jackett mit weißen Knöpfen. Darunter trägt er ein weißes Hemd mit aufgestickten Verzierungen in den Farben Gelb, Rot und Blau."
              caption="Hölderlins Vater"
            />
          </Grid>

          <Stack space={6}>
            <Heading level={5}>Die Mutter</Heading>
            <Paragraph>
              Als ihr Sohn Friedrich zur Welt kommt, ist Johanna Christiana 22
              Jahre alt.
            </Paragraph>
          </Stack>

          <Stack space={6}>
            <Heading level={5}>Der Vater</Heading>
            <Paragraph>
              Hölderlins Vater arbeitet als Verwalter im Kloster von Lauffen.{" "}
              <br />
              Er stirbt schon 2 Jahre nach Hölderlins Geburt bei einem Unfall.
            </Paragraph>
          </Stack>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Die Schwester Rike und der Bruder Karl
          </Heading>
          <Grid columns={[1, 2]} space={[6, 3]}>
            <Image
              image={data.rike.childImageSharp.gatsbyImageData}
              alt="Eine Illustration zeigt Hölderlins Schwester Rike. Sie hat blonde Haare. Vor ihr liegt ein Brief."
              caption="Die Schwester Rike"
            />
            <Image
              image={data.karl.childImageSharp.gatsbyImageData}
              alt="Eine Illustration zeigt Hölderlins Bruder Karl neben einer Weinrebe."
              caption="Der Bruder Karl"
            />
          </Grid>
          <Paragraph>
            Hölderlins Schwester Maria Eleonora Heinrike ist 2 Jahre jünger als
            Hölderlin. <br />
            Später, als Hölderlin nicht mehr zuhause wohnt, schreibt er ihr
            viele Briefe.
          </Paragraph>
          <Paragraph>
            Hölderlins Halb-Bruder Karl ist 6 Jahre jünger. <br />
            Anders als Hölderlin kann er nicht studieren. <br />
            Er arbeitet als Beamter.
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Georg Wilhelm Friedrich Hegel und Friedrich Wilhelm Joseph Schelling
          </Heading>
          <Grid columns={[1, 2]} space={[6, 3]}>
            <Image
              image={data.hegel.childImageSharp.gatsbyImageData}
              alt="Ein Ölgemälde zeigt Georg Wilhelm Friedrich Hegel. Er hat Tränensäcke unter den Augen und sieht grimmig aus."
              caption="Hegel"
            />
            <Image
              image={data.schelling.childImageSharp.gatsbyImageData}
              alt="Ein Ölgemälde zeigt Friedrich Wilhelm Schelling. Er hat kurze graue Locken und lächelt ernst."
              caption="Schelling"
            />
          </Grid>
          <Paragraph>
            Diese beiden Freunde lernt Hölderlin in Tübingen kennen. <br />
            Sie studieren zusammen. <br />
            Und sie teilen sich ein Zimmer.
            <br />
            Sie gehen gemeinsam feiern. <br />
            Sie möchten die Welt verändern. <br />
            Nicht nur für sich, sondern für alle Menschen.
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Isaak von Sinclair
          </Heading>
          <Grid columns={[1, 2]} alignY="center">
            <Image
              image={data.sinclair.childImageSharp.gatsbyImageData}
              alt="Porträt von Isaac von Sinclair. Er hat blonde Haare, eine breite Stirn und ein schmales Kinn. Er sieht traurig aus."
            />
            <Paragraph>
              Sinclair ist immer für Hölderlin da. <br />
              Er kümmert sich um ihn. <br />
              Manche sagen auch, Sinclair war in Hölderlin verliebt.
            </Paragraph>
          </Grid>
        </Stack>
        <Stack>
          <Heading as="h2" level={3}>
            Susette Gontard
          </Heading>
          <Grid columns={[1, 2]} alignY="center">
            <Image
              image={data.bueste.childImageSharp.gatsbyImageData}
              attribution="David Franck"
              alt="Eine kleine weiße Figur von einer Frau. Die feinen Lockenhaare sind zu einem Zopf hochgesteckt."
            />
            <Paragraph>
              Susette ist für Hölderlin die wichtigste Frau in seinem Leben.
              <br />
              Als sie sich kennenlernen, ist Susette 27 Jahre alt und hat vier
              Kinder. <br />
              Hölderlin und Susette verlieben sich. <br />
              Doch Susette ist verheiratet. <br />
              Die beiden müssen sich trennen.
            </Paragraph>
          </Grid>
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtMenschenPage
